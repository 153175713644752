import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import Title from "../components/crumbs/title"
import PageSeo from "../components/crumbs/pageSeo"
import MoreArticles from "../components/crumbs/moreArticles"
import { useStaticQuery, graphql } from "gatsby"
import CategoryTopNews from "../components/crumbs/categoryTopNews"
import Ads from "../components/ads"
import Helmet from "react-helmet"
import BoxAd from "../components/crumbs/boxAd"

export default function Home() {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Időjárás"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }
    }
  `)

  const articlesTopNews = allStrapiArticle.edges.map(_ => _.node)

  return (
    <>
      {/*<Helmet><script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script></Helmet>*/}
      <PageSeo
        title="Minden Percben | Időjárás"
        description="Minden amiről tudnia érdemes az időjárással kapcsolatosan. Mert minden perc érdekes!"
        keywords="időjárás, aktuális időjárás, kárpát medence időjárás, útinform"
        canonical="https://www.mindenpercben.hu/idojaras"
        canonicalMobile = "https://m.mindenpercben.hu/idojaras"        
      />
      <LayoutSite>
        <Title category="Időjárás" />
        
        <CategoryTopNews articles={articlesTopNews} />

        <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/1213172.html" width="1100" height="320" frameborder="0" scrolling="no"></iframe> } />

        <MoreArticles slug="/kereses/idojaras/1" text="Az Időjárás rovat további cikkei" />
      </LayoutSite>
    </>
  )
}